import React, { Component } from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import Breadcrumb from "../elements/common/Breadcrumb"
import Testimonial from "../elements/Testimonial"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp } from "react-icons/fi"
import Footer from "../component/footer/Footer"
import ContactThree from "./contact/ContactThree"

const contentOptions = {
  renderMark: {
    [MARKS.ITALIC]: text => (
      <>
        <span>{text}</span>
      </>
    ),
  },

  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className="title">{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="title">{children}</h3>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="description">{children}</p>
    ),

    [BLOCKS.UL_LIST]: (node, children) => <ul style={{listStyleType: 'none'}}>{children}</ul>
  },
}

class About extends Component {
  render() {
    const { headerImage, title, content, sections } = this.props.data

    return (
      <React.Fragment>
        {/* Start Breadcrump Area */}
        <Breadcrumb title={title} />
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={headerImage.fluid.src}
                      alt="John Mercer"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      {documentToReactComponents(content.json, contentOptions)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Experience Start*/}
        <div className="rn-columns-area ptb--15 bg_color--1">
          <div className="container">
            <div className="row">
              {sections.map( (section, id) => {
                return (
                  <div className="col-lg-6 col-md-6 col-12" key={id}>
                    <div className="single-column" style={{textAlign: 'center' }}>
                      <h3 className="title">{section.title}</h3>
                      {documentToReactComponents(section.sectionContent.json, contentOptions)}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {/** Experience End */}

        {/* Start Testimonial Area */}
        <div className="rn-testimonial-area bg_color--5 ptb--120" id="testimonial-area">
          <div className="container">
            <Testimonial />
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--120 bg_color--1">
          <ContactThree />
        </div>
        {/* End Portfolio Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    )
  }
}
export default About
