import React, { Component } from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { useStaticQuery, graphql } from "gatsby"
const quoteOptions = {
  renderMark: {
    [MARKS.ITALIC]: text => (
      <>
        <span>{text}</span>
      </>
    ),
  },

  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
  },
}

const Quote = ({ author, occupation, children }) => {
  return (
    <div className="rn-testimonial-content text-center">
      <div className="inner">
        {documentToReactComponents(children.json, quoteOptions)}
      </div>
      <div className="author-info">
        <h6>
          <span>{author} </span> - {occupation}
        </h6>
      </div>
    </div>
  )
}

const Testimonial = () => {
  const { allContentfulTestimonial: testimonials } = useStaticQuery(graphql`
    query TestimonialsQuery {
      allContentfulTestimonial {
        edges {
          node {
            testimonial {
              json
            }
            clientName
            clientCompany
            website
            clientImage {
              fluid(
                cropFocus: FACE
                resizingBehavior: THUMB
                maxHeight: 128
                maxWidth: 128
              ) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const testimonialList = testimonials.edges

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            {testimonialList.map((testimony, id) => {
              const { node } = testimony
              return (
                <TabPanel key={id}>
                  <Quote
                    author={node.clientName}
                    occupation={node.clientCompany}
                    children={node.testimonial}
                  />
                </TabPanel>
              )
            })}

            <TabList className="testimonial-thumb-wrapper">
              {testimonialList.map((testimony, id) => {
                const { node } = testimony
                return (
                  <Tab key={id}>
                    <div className="testimonial-thumbnai">
                      <div className="thumb"
                        
                      >
                        <img
                          src={node.clientImage ? node.clientImage.fluid.src : "/assets/images/logo/whittyshield.png"}
                          alt={`${node.clientName} image`}
                          style={{maxWidth: '64px'}}
                        />
                      </div>
                    </div>
                  </Tab>
                )
              })}
            </TabList>
          </Tabs>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Testimonial
