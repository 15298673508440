import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import { useStaticQuery, StaticQuery } from 'gatsby'

const SocialShare = [
  {
    Social: <FaFacebookF />,
    link: "https://www.facebook.com/",
    name: "facebook",
  },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/",
    name: "linkedin",
  },
  {
    Social: <FaInstagram />,
    link: "https://www.instagram.com/",
    name: "instagram",
  },
  { Social: <FaTwitter />, link: "https://twitter.com/", name: "twitter" },
]

const Footer = () => {

    const data = useStaticQuery(graphql`
            query {
              allContentfulSocialMediaAccount {
                nodes {
                  url
                  socialSite
                }
              }
            }
          `)

    const { allContentfulSocialMediaAccount } = data

    const socialMedia = allContentfulSocialMediaAccount.nodes

    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>All Ready?</span>
                    <h2>
                      Let's get <br /> started
                    </h2>
                    <a className="rn-button-style--2" href="/contact">
                      <span>Get Started</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4>Menu</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="/who-is-john-mercer">
                              Who is John Mercer?
                            </a>
                          </li>
                          <li>
                            <a href="/journal">Journal</a>
                          </li>
                          <li>
                            <a href="/contact">Get Started</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <h4>Get in touch</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="mailto:john@coachmercer.com">
                              john@coachmercer.com
                            </a>
                          </li>
                          <li>
                            <a href="phone:6523038668">651-303-8668</a>
                          </li>
                        </ul>

                        <div className="social-share-inner">
                          <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                            {socialMedia.map((val, i) => {
                              const social = SocialShare.find(
                                soc => soc.name === val.socialSite.toLowerCase()
                              )

                              return (
                                <li key={i}>
                                  <a href={`${val.url}`}>{social.Social}</a>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    )
}

export default Footer;