import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./../index.scss"
import About from "../elements/About"

const AboutPage = props => (
  <Layout>
    <SEO title={props.data.contentfulPage.title} image={props.data.contentfulPage.headerImage.fluid.src} description={props.data.contentfulPage.description.description} lang='english' />
    <About data={props.data.contentfulPage} />
  </Layout>
)

export const pageQuery = graphql`
         query {
           contentfulPage(url: { eq: "who-is-john-mercer" }) {
             id
             description {
               description
             }
             content {
               json
             }
             headerImage {
               fluid {
                 src
               }
             }
             sections {
               sectionContent {
                 json
               }
               title
             }
             title
           }
         }
       `
export default AboutPage
